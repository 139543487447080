.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* View transitions */
@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-to-right {
  to {
    transform: translateX(90px);
  }
}

@keyframes slide-to-left-low {
  to {
    transform: translateX(-24px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-90px);
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(90px);
  }
}

@keyframes slide-from-right-low {
  from {
    transform: translateX(24px);
  }
}

@keyframes slide-from-left {
  from {
    transform: translateX(-90px);
  }
}

@keyframes slide-from-top {
  from {
    transform: translateY(-24px);
  }
}

@keyframes slide-to-top {
  to {
    transform: translateY(24px);
  }
}

@keyframes slide-to-bottom {
  to {
    transform: translateY(-90px);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.9);
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.9);
  }
}

@keyframes scale-down-low {
  to {
    transform: scale(0.98);
  }
}

@keyframes scale-up-low {
  from {
    transform: scale(0.98);
  }
}

.fade-over {
  view-transition-name: fade-over;
}

.slide-top {
  view-transition-name: slide-top;
}

.slide-left {
  view-transition-name: slide-left;
}

.slide-right {
  view-transition-name: slide-right;
}

.slide-right-low {
  view-transition-name: slide-right-low;
}

.scale {
  view-transition-name: scale;
}

/*a.transitioning {
    view-transition-name: slide-it;
}*/

::view-transition-old(fade-over) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out;
}

::view-transition-new(fade-over) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in;
}

::view-transition-old(slide-top) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-top;
}

::view-transition-new(slide-top) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-top;
}

::view-transition-old(slide-left) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(slide-left) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-left;
}

::view-transition-old(slide-right) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-right;
}

::view-transition-new(slide-right) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

::view-transition-old(slide-right-low) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left-low;
}

::view-transition-new(slide-right-low) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right-low;
}

::view-transition-old(scale) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both scale-down;
    transform-origin: center;
}

::view-transition-new(scale) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both scale-up;
    transform-origin: center;
}

